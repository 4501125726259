<template>
    <div class="modal-select-dropout-reason">
        <Loading :loading="loading" />

        <div class="textbox">
            <div class="title f-20 p-t-40 m-l-25 m-r-18">탈퇴 사유를 선택해주세요. (복수 선택)</div>
            <ul class="reason flex-fill p-t-25 p-l-30 p-r-20">
                <li
                    @click="onClickItem(item)"
                    class="item flex-row items-center flex-between"
                    :class="{ selected: item.$$selected }"
                    :key="item.id"
                    v-for="item in reasonItems"
                >
                    <div v-html="$translate(item.name)" />
                </li>

                <div class="reason flex-fill p-t-10" v-if="isSelected === true">
                    <TextareaWithX
                        v-model="inputValue"
                        :placeholder="placeholder"
                        :textareaRows="4"
                        :ref="'comment'"
                        :setClass="'content-area m-0'"
                    />
                </div>
            </ul>
            <div class="bottom-button">
                <button
                    class="btn-send-reason f-18"
                    :class="{ disabled: disabled }"
                    @click="onClickBtn(options.status)"
                    :disabled="disabled"
                >
                    탈퇴하기
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ModalDropout',
    props: ['options'],
    data: () => ({
        reasonItems: [],
        selectedValue: [],
        inputValue: '',
        disabled: true,
        isSelected: false,
        loading: false,
    }),
    async mounted() {
        await this.dropoutOptions()
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    watch: {
        selectedValue() {
            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }
        },
    },
    computed: {
        placeholder() {
            return '탈퇴 사유를 입력해주세요.'
        },
    },
    methods: {
        async dropoutOptions() {
            const allReasons = this.$store.getters.inactiveReasons
            this.reasonItems = allReasons.filter(item => item.inactive_type === 'dropout')
        },
        onClickItem(item) {
            this.$set(item, '$$selected', !item.$$selected)

            this.selectedValue = this.reasonItems.filter(o => o.$$selected)

            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }

            this.isSelected = this.selectedValue.some(o => o.name === '기타')
        },
        async onClickBtn() {
            try {
                if (this.selectedValue.some(item => item.id === 4)) {
                    await this.$modal
                        .basic({
                            body:
                                '고객님 본인의 프로필을 다른 사람에게 절대 먼저 공개하지 않고, 고객님이 수락하신 사람에게만 프로필을 전달하는 슈퍼 프라이버시 기능이 존재합니다. 슈퍼 프라이버시 기능을 이용해 보시겠습니까?',
                            buttons: [
                                {
                                    label: '아니오',
                                    class: 'btn-default',
                                },
                                {
                                    label: '예',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(async idx => {
                            if (idx === 1) {
                                this.$emit('close')
                                this.$stackRouter.pop()
                                // this.$router.go(-1)
                                this.$stackRouter.push({ name: 'SuperPrivacyIntroductionPage' })
                            } else {
                                try {
                                    await this.$modal
                                        .basic({
                                            body:
                                                '탈퇴를 신청합니다. 고객센터에서 진행중인 약속과 환불해드릴 내역이 없는지 확인 후에 최종 탈퇴 처리해드립니다. 감사합니다.',
                                            buttons: [
                                                {
                                                    label: 'CANCEL',
                                                    class: 'btn-default',
                                                },
                                                {
                                                    label: 'CONFIRM',
                                                    class: 'btn-primary',
                                                },
                                            ],
                                        })
                                        .then(async idx => {
                                            if (idx === 1) {
                                                try {
                                                    const ret = await userService.dropout({
                                                        inactive_reason_ids: this.selectedValue.map(item => item.id),
                                                        inactive_reason_etc: this.inputValue,
                                                    })

                                                    if (ret.msg === 'success') {
                                                        this.$router.go(-2)
                                                    }
                                                } catch (err) {
                                                    console.error(err)
                                                }
                                            }
                                        })
                                } catch (e) {
                                    this.$toast.error(e.data)
                                }
                            }
                        })
                } else {
                    await this.$modal
                        .basic({
                            body:
                                '탈퇴를 신청합니다. 고객센터에서 진행중인 약속과 환불해드릴 내역이 없는지 확인 후에 최종 탈퇴 처리해드립니다. 감사합니다.',
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(async idx => {
                            if (idx === 1) {
                                try {
                                    const ret = await userService.dropout({
                                        inactive_reason_ids: this.selectedValue.map(item => item.id),
                                        inactive_reason_etc: this.inputValue,
                                    })

                                    if (ret.msg === 'success') {
                                        this.$router.go(-2)
                                    }
                                } catch (err) {
                                    console.error(err)
                                }
                            }
                        })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-select-dropout-reason {
    position: relative;
    .title {
        font-weight: 600;
        text-align: center;
    }
    .description {
        color: #696969;
        line-height: 19.2px;
    }
    ul {
        margin-bottom: 100px;

        li {
            @include center;
            padding: 17px;
            color: #888888;
            size: 14px;
            background: white;
            margin-bottom: 8px;
            border-radius: 8px;
            border: 1px solid #efdfd2;
            font-weight: 400;

            &.selected {
                color: #151360;
                border: 1px solid #151360;
                font-weight: 600;
            }
        }
    }
    .bottom-button {
        padding: 12px 20px 20px 20px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff9ef;
        .btn-send-reason {
            height: 48px;
            width: 100%;
            border: none;
            border-radius: 36px;
            background: #151360;
            color: white;

            &.disabled {
                background: #d7d5d1;
            }
        }
    }
}
</style>
